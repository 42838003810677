<template>
    <div>
        <Header></Header>
        <div class="nbanner">
          <img src="@/assets/images/newbanner.jpg" alt="">
        </div>
        <div class="nnews">
          <div class="nnews2">
            <div class="ntitle">{{ $t('news') }}</div>
              <div class="news-item" v-for="(item,index) in newslist" :key="index">
                  <div class="newsimg">
                    <router-link  :to="{ name: 'HomeNewsDetail', params: { newsId: item.newsId }}">
                      <img :src="item.image" alt="">
                    </router-link>
                  </div>
                  <div class="newsinfo">
                    <router-link :to="{ name: 'HomeNewsDetail', params: { newsId: item.newsId }}">
                      <div class="newsinfo1">{{item.title}}</div>
                      <div class="newsinfo2">
                            {{ item.content | removeHtml }}
                      </div>
                     </router-link>
                    <div class="newsinfo3">{{item.publishedAt}}</div>
                  </div>
               
              </div>
            <!-- 分页 -->
            <div class="page" style="width: 1200px;">
              <a-pagination
                showQuickJumper
                :defaultCurrent="pagination.defaultCurrent"
                :defaultPageSize="pagination.defaultPageSize"
                :total="pagination.total"
                @change="onChange"
                @showSizeChange="onShowSizeChange"
              />
            </div>
          </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>

import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  name: "News",
  components: {
    Footer,
    Header,
  },
  data () {
    return {
      page: 1,
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        defaultCurrent: 1, // 默认当前页数
        defaultPageSize: 5, // 默认当前页显示数据的大小
        total: 0 // 总数，必须先有
      },
      newslist: []
    }
  }, 
  mounted () {
    this.getList()
  },
  methods: {
    //分页
    onShowSizeChange (current, pageSize) {
      this.pagination.defaultCurrent = 1
      this.pagination.defaultPageSize = pageSize
      this.getList() // 显示列表的接口名称
    },
    onChange(current, size) {
      this.pagination.defaultCurrent = current
      this.pagination.defaultPageSize = size
      // 查数据
      this.getList()
    },
    // 获取展会详情
    getList () {
      const params = {
        page: this.pagination.defaultCurrent,
        pagesize: this.pagination.defaultPageSize
      }
      this.$common.fetchList('/article/lists', Object.assign({}, params)).then(data => {
        if (data.code === 200) {
          const result = data.data
          const pagination = { ...this.pagination }
          pagination.total = result.totalInfo.total_items
          this.newslist = result.lists
          this.pagination = pagination
          console.log(this.newslist)
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    },
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/news.less';
</style>